// 接口路径
// let baseUrl = 'https://java.app.minshangkeji.com.cn';
// let baseUrl = 'https://liangxin.java.weimobms.cn/api';

let baseUrl = "api";
// 上传图片接口
let actionUrl = baseUrl + "/web/tool/file/upload";
// 图片路劲
// let imageUrl = 'https://java.admin.minshangkeji.com.cn';
let imageUrl = "https://lx.weimobms.cn";

export { baseUrl, actionUrl, imageUrl };
